import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

import manastirLogo from "../../content/assets/logo-black.png"

import postStyles from "./blog-post.module.css"
import axios from "axios"
class BlogPostTemplate extends React.Component {
  state = {
    name: "",
    surname: "",
    email: "",
    phone: "",
    cv: "",
    form_id: "",
    job_name: "",
    cv: "",
    form_submit: false,
    form_message: "",
    form_error: false,
    form_warning: false,
    file_name: "",
    cover_letter: "",
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    // const file_name =

    let file_name = ""

    if (event.target.files && event.target.files.length > 0) {
      file_name = event.target.files[0].name
    }

    this.setState({
      [name]: value,
      file_name: file_name,
    })
  }

  handleSubmit = event => {
    let self = this
    event.preventDefault()
    const data = new FormData(event.target)
    axios
      .post("https://api.jobs.manastir.net/api/applications", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function(response) {
        console.log(response.data)
        if (response.data.status === "success") {
          self.state.name = ""
          self.state.surname = ""
          self.state.email = ""
          self.state.phone = ""
          self.state.cv = ""
          self.state.file_name = ""
          self.state.cover_letter = ""
          self.setState({
            form_warning: false,
            form_error: false,
            form_submit: true,
            form_message: response.data.message,
          })
        } else if (response.data.status === "error") {
          self.state.name = ""
          self.state.surname = ""
          self.state.email = ""
          self.state.phone = ""
          self.state.cv = ""
          self.state.file_name = ""
          self.state.cover_letter = ""
          self.setState({
            form_warning: false,
            form_error: true,
            form_submit: false,
            form_message: response.data.message,
          })
        } else if (response.data.status === "warning") {
          self.state.name = ""
          self.state.surname = ""
          self.state.email = ""
          self.state.phone = ""
          self.state.cv = ""
          self.state.file_name = ""
          self.state.cover_letter = ""
          self.setState({
            form_warning: true,
            form_error: false,
            form_submit: false,
            form_message: response.data.message,
          })
        }
      })
  }

  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    let companyLogo = id => {
      let logo
      id = id * 1
      switch (id) {
        case 1:
          logo = manastirLogo
          break
        default:
          break
      }

      return <img className={postStyles.company_logo} src={logo} />
    }

    let companySite = (id, children) => {
      let url, alt, text
      id = id * 1
      switch (id) {
        case 1:
          url = "https://manastir.net"
          alt = "Manastır"
          text = "manastir.net"
          break

        default:
          url = "https://manastir.net"
          alt = "Manastır"
          text = "manastir.net"
          break
      }

      return (
        <a href={url} alt={alt} target="_blank">
          {children || text}
        </a>
      )
    }

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        style={{ display: `flex`, flexWrap: `wrap` }}
      >
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <div className={postStyles.job_detail}>
          <div className={postStyles.header_wrapper}>
            <h1 className={postStyles.job_title}>{post.frontmatter.title}</h1>
            {/* {companySite(
              post.frontmatter.company_logo,
              companyLogo(post.frontmatter.company_logo)
            )} */}
          </div>
          <div
            className={postStyles.rich_text}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          {companySite(post.frontmatter.company_logo)}
          <hr
            style={{
              marginTop: rhythm(1),
              marginBottom: rhythm(1),
            }}
          />

          <ul className={postStyles.breadcrumb}>
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </div>
        <div className={postStyles.application_form}>
          <h1 className={postStyles.job_title}>Bu İlana Başvuru Yap</h1>

          <form
            method="POST"
            onSubmit={this.handleSubmit}
            className={postStyles.form}
          >
            <input
              type="hidden"
              name="job_name"
              value={post.frontmatter.title}
              onChange={this.handleInputChange}
            />

            <label>Adınız (gerekli)</label>
            <input
              style={{ display: "block", marginBottom: 20, width: "100%" }}
              type="text"
              name="name"
              value={this.state.name}
              onChange={this.handleInputChange}
              required
            />

            <label>Soyadınız (gerekli)</label>
            <input
              style={{ display: "block", marginBottom: 20, width: "100%" }}
              type="text"
              name="surname"
              value={this.state.surname}
              onChange={this.handleInputChange}
              required
            />

            <label>E-posta adresiniz (gerekli)</label>
            <input
              style={{ display: "block", marginBottom: 20, width: "100%" }}
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.handleInputChange}
              required
            />

            <label>Telefon numarası (gerekli)</label>
            <input
              style={{ display: "block", marginBottom: 20, width: "100%" }}
              placeholder="05xxxxxxxxx"
              type="text"
              name="phone"
              value={this.state.phone}
              onChange={this.handleInputChange}
              required
            />

            <label>CV (PDF formatında en fazla 3mb)</label>

            {!!this.state.file_name && (
              <span style={{ display: `block` }}>
                <b>Seçili Dosya:</b> {this.state.file_name}{" "}
              </span>
            )}
            <div className={postStyles.file_input}>
              <div className={postStyles.file_input_text}>Dosya yükle</div>
              <input
                type="file"
                name="cv"
                value={this.state.cv}
                onChange={this.handleInputChange}
                className={postStyles.formControl}
                required
              />
            </div>

            <label style={{ display: "block" }}>Önyazı</label>
            <textarea
              rows="5"
              style={{ display: "block", marginBottom: 20, width: "100%" }}
              name="cover_letter"
              value={this.state.cover_letter}
              onChange={this.handleInputChange}
            />

            <button className={postStyles.button} type="submit">
              BAŞVUR
            </button>
          </form>
          {this.state.form_submit && <p>{this.state.form_message}</p>}
          {this.state.form_error && <p>{this.state.form_message}</p>}
          {this.state.form_warning && <p>{this.state.form_message}</p>}
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        company_logo
      }
    }
  }
`
