import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"

import logo from "../../content/assets/logo.svg"

import layoutStyles from "./layout.module.css"

class Layout extends React.Component {
  render() {
    const { location, title, children, style } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header = (
      <>
        <a
          href={`https://manastir.net`}
          style={{
            textDecoration: `none`,
            boxShadow: `none`,
            display: `flex`,
          }}
        >
          <img src={logo} className={layoutStyles.logo} />
        </a>
        {/* <img src={logo} className={layoutStyles.logo} /> */}
        <h3 className={layoutStyles.title}>
          <Link className={layoutStyles.title_link} to={`/`}>
            {title}
          </Link>
        </h3>
      </>
    )
    return (
      <>
        <header className={layoutStyles.header}>{header}</header>
        <div className={layoutStyles.container}>
          <main style={style}>{children}</main>
        </div>
        <footer className={layoutStyles.footer}>
          <a
            href="https://manastir.net"
            target="_blank"
            className={layoutStyles.copyright}
          >
            © {new Date().getFullYear()} Manastır
          </a>
        </footer>
      </>
    )
  }
}

export default Layout
